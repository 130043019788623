@import 'styles/helpers/_extends';

.spa__time-picker {
    .ant-picker {
        padding: 5px 10px 6px 10px;
        position: relative;
        border-radius: $normal-radius;
        width: 100%;

        input {
            cursor: pointer;
        }

        .ant-picker-range-separator {
            .ant-picker-separator {
                display: none;
            }
            &:after {
                content: '-';
            }
        }
    }

    .ant-form-item-label {
        label {
            @extend %f-medium;
        }
    }

    .ant-form-item-explain {
        font-size: $small-size;
        > div {
            margin-top: 5px;
        }
    }

    &.ant-form-item-has-error {
        margin: 0;
    }

    .ant-form-item-label {
        @extend %f-medium;
    }

    &.large {
        .ant-picker {
            padding: 11px 14px;
        }
    }
}