@import 'styles/helpers/_extends';

$sidebar-width: 300px;
$sidebar-collapse-width: 80px;

.spa__sidebar {
    width: $sidebar-collapse-width;
    background-color: $sidebar-blue;
    @extend %enable-transition;
    @include flex(column, flex-start);

    > button {
        background-color: transparent;
        color: rgba($white, 0.7);
        border: none;
        box-shadow: none;
        font-size: $h2;
        margin-left: 10px;
        cursor: pointer;
        margin: 20px 15px 20px 25px;

        &.expanded {
            margin-left: 15px;
        }
    }

    > div {
        height: auto !important;
        flex-grow: 1;
    }

    ul {
        margin-bottom: 25px;
        li {
            margin: 0 15px;
            a {
                color: $white;
                display: block;
                padding: 10px;
                @extend %hoverable;
                border-radius: $normal-radius;
                @include flex(row, center);
                white-space: nowrap;
                overflow: hidden;
                @extend %enable-transition;

                i {
                    font-size: $h2;
                    min-width: 30px;
                    text-align: center;
                }

                span {
                    padding-left: 20px;
                }

                &:hover {
                    background-color: rgba($white, 0.05);
                }
            }

            &.spa__sidebar--label {
                color: $white;
                margin: 15px;
                @include flex(row, center);
                overflow: hidden;
                
                .label {
                    width: 0;
                    overflow: hidden;
                    @extend %enable-transition;
                }

                .combo {
                    color: $light-yellow;
                    word-wrap: break-word;
                    flex-grow: 1;
                    padding-left: 7px;
                    @extend %enable-transition;
                    width: 100%;
                }
            }

            &.active {
                a {
                    background-color: rgba($white, 0.15);
                }
            }
        }
    }

    &.expanded {
        width: $sidebar-width;

        ul {
            li {
                a {
                    span {
                        display: inline-block;
                    }
                }

                &.spa__sidebar--label {
                    margin: 15px 25px;
                    .label {
                        width: 56px;
                    }

                    .combo {
                        padding: 0;
                        width: auto;
                    }
                }
            }
        }
    }
}