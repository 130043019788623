@import 'styles/helpers/_extends';

$icon-width: 175px;
$icon-width-large: 287px;

.spa__app-download {
    .note {
        color: $gray_8;
    }

    &-ctas {
        a {
            box-shadow: $shadow;
            border-radius: $normal-radius;
            overflow: hidden;
            display: inline-block;
            margin: 0 10px;

            img {
                width: $icon-width;
            }
        }
    }

    > .spa__link {
        display: inline-block;
        margin-top: 20px;
    }

    &.large {
        .spa__app-download-ctas {
            a {
                img {
                    width: $icon-width-large;
                }
            }
        }
    }
}