@import 'styles/helpers/_extends';

$size: 143px;

.spa__logo {
    padding: $content-padding 0;
    text-align: center;

    img {
        width: $size;
    }
}