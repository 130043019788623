@import 'styles/helpers/_extends';

.spa__spinner {
    @include flex(column, center, center);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: $gray_f;
    z-index: 1100;
    @extend %enable-transition;
    @extend %loading-icon;

    &.hidden {
        @include opacity(0);
        visibility: hidden;
        pointer-events: none;
    }

    &.transparent {
        background-color: $blue-alpha;

        &:after {
            border-color: $white;
            border-left-color: $dark-blue;
        }
    }
}