@import 'styles/helpers/_extends';

.spa__modal {
    .ant-modal-content {
        border-radius: $big-radius;
        overflow: hidden;
        box-shadow: 0 10 20 rgba(103, 118, 140, 0.25);

        .ant-modal-close {
            top: 4px;
        }

        .ant-modal-header {
            border-bottom: 1px solid $gray_d;
            padding: 20px 24px;
            .ant-modal-title {
                font-size: $h2;
                @extend %f-bold;
                text-align: center;
            }
        }
    }

    &.no-padding {
        .ant-modal-body {
            padding: 0;   
        }
    }
}