html {
    scroll-behavior: smooth;

    * {
        text-rendering: optimizeLegibility !important;
        -webkit-font-smoothing: antialiased !important;
        -moz-osx-font-smoothing: grayscale !important; 
        text-shadow:1px 1px 1px rgba(0,0,0,0.005);
    }

    body {
        @extend .f-regular;
        font-size: $base-size;
        background-color: $gray_f;
        line-height: 1.2;
        color: $text-gray;
        overflow-x: hidden;
    
        &.modal-open {
            overflow: hidden;
        }
    }

    a {
        text-decoration: none;
        @extend %hoverable;

        &:hover {
            text-decoration: none;
        }
    }

    button {
        outline: none;
        border: 0;

        &:focus {
            outline: none;
        }
    }

    p {
        margin-bottom: 5px;
    }

    ul, ol {
        list-style: none;
        padding: 0;
        margin: 0;
    }
}