@import 'styles/helpers/_extends';

.spa__input-number {
    @include flex(row, center);
    .ant-form-item {
        flex-grow: 1;
        &-label {
            label {
                @extend %f-medium;
            }
        }

        &-control {
            &-input {
                &-content {
                    .ant-input-number {
                        border-radius: $normal-radius;
                        background-color: $white;
                        border-color: 1px solid $gray_d;
                        width: 100%;

                        &-handler-wrap {
                            display: none;
                        }

                        input {
                            padding: 14px;
                            line-height: 1;
                            height: auto;

                            &[disabled] {
                                background-color: $gray_e;
                                pointer-events: none;
                            }
                        }
                    }
                }
            }
        }

        &-explain {
            font-size: $small-size;
            > div {
                margin-top: 5px;
            }
        }
    }
    .suffix {
        position: relative;
        p {
            @include centered-position(false, true);
            right: 0;
            padding: 11px 14px;
            border-left: 1px solid $gray_d;
            margin: 5px 0 0 0;
        }
    }

    &.m-0 {
        .ant-form-item {
            margin: 0;
        }
    }
}