@import 'styles/helpers/_extends';

$icon-size: 142px;

.spa__app-required {
    &--icon {
        @include flex(row, center, center);
        width: $icon-size;
        height: $icon-size;
        border-radius: $icon-size;
        border: 1px solid $green;
        box-shadow: $shadow;
        background-color: $white;
        margin: 0 auto;

        i {
            font-size: $huge-size * 2.5;
            color: $green;
        }
    }

    > p {
        font-size: $larger-size;
        line-height: 1.5;
    }
}