@import 'styles/helpers/_extends';

$icon-size: 45px;

.spa__notification {
    .ant-drawer-content-wrapper {
        .ant-drawer-body {
            padding: 0;
            display: flex;
            flex-direction: column;

            .ant-tabs {
                @include flex(column);
                flex-grow: 1;
                .ant-tabs-nav {
                    margin: 0;
                }

                .ant-tabs-nav-list {
                    .ant-tabs-tab {
                        width: 50%;
                        @include flex(row, center, center);
                    }

                    .ant-tabs-ink-bar {
                        width: 50% !important;
                    }
                }

                .ant-tabs-content-holder {
                    flex-grow: 1;
                    @include flex(column);
                }

                .ant-tabs-content {
                    flex-grow: 1;
                    .spa__notification--activities {
                        &--item {
                            @include flex(row, center, center);
                            padding: 15px;
                            border-bottom: 1px solid $gray_d;
                            cursor: pointer;
                            background-color: $white;
                            width: 100%;

                            > i {
                                width: $icon-size;
                                height: $icon-size;
                                border: 1px solid $gray_d;
                                border-radius: $icon-size;
                                @include flex(row, center, center);
                                font-size: $h3;
                                color: $blue;
                                margin-right: 10px;
                            }

                            .content {
                                width: calc(100% - #{$icon-size} - 10px);
                                text-align: left;

                                .h5 {
                                    span {
                                        line-height: 1.2;
                                    }

                                    small {
                                        color: $gray_9;
                                        font-size: $small-size;
                                        @extend %f-regular;
                                        min-width: 100px;
                                        text-align: right;
                                    }
                                }

                                > p {
                                    font-size: $middle-size;
                                }
                            }
                        }
                    }

                    .spa__notification--news {
                        &--item {
                            @include flex(row, center, center);
                            padding: 15px;
                            border-bottom: 1px solid $gray_d;
                            cursor: pointer;
                            background-color: $white;
                            width: 100%;

                            > i {
                                width: $icon-size;
                                height: $icon-size;
                                border: 1px solid $gray_d;
                                border-radius: $icon-size;
                                @include flex(row, center, center);
                                font-size: $h3;
                                color: $blue;
                                margin-right: 10px;
                            }

                            .content {
                                width: calc(100% - #{$icon-size} - 10px);
                                text-align: left;

                                small {
                                    color: $gray_9;
                                    font-size: $small-size;
                                    @extend %f-regular;
                                    min-width: 100px;
                                    text-align: right;
                                }
    
                                > p {
                                    font-size: $middle-size;
                                }
    
                                .spa__link {
                                    font-size: $small-size;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.spa__news {
    @include flex(column, center);
    img {
        width: 150px;
        max-width: 95%;
    }

    h3 {
        border-top: 1px solid $gray_d;
        width: 100%;
    }
}