@import 'styles/helpers/_extends';

.spa__appointment-filter {
    padding: $content-padding;

    .spa__button {
        .ant-btn {
            &.add-new {
                button {
                    padding-left: 30px;
                    padding-right: 30px;
                }
            }
        }
    }

    .spa__date-picker {
        .ant-picker {
            border-radius: 0;
        }
    }
}