@import 'styles/helpers/_extends';

$sidebar-width: 400px;

.spa__modal-page {
    @include flex(column);
    height: 100vh;
    &--title {
        position: relative;
        padding: 10px;
        border-bottom: 1px solid $gray_d;
        background-color: $white;

        button {
            @include centered-position(false, true);
            background-color: transparent;
            border: none;
            outline: none;
            cursor: pointer;

            i {
                font-size: $h4;
            }

            &.closeBtn {
                right: 10px;
            }

            &.backBtn {
                left: 10px;
            }
        }
    }
    &--body {
        flex-grow: 1;
        overflow: hidden;
        &--form {
            flex-grow: 1;
        }

        &--sidebar {
            width: $sidebar-width;
            background-color: $white;
            border-left: 1px solid $gray_d;
        }
    }

    &--footer {
        padding: 10px;
        border-top: 1px solid $gray_d;
        background-color: $white;
    }
}