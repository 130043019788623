@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/vendor/rfs";

// Colours
$white:               #FFFFFF;
$text-gray:           #202020;
$gray_d:              #DDDDDD;
$gray_e:              #EEEEEE;
$gray_c:              #CCCCCC;
$gray_9:              #999999;
$gray_8:              #888888;
$gray_7:              #777777;
$gray_f:              #F2F2F7;
$gray_fb:             #FBFBFB;
$gray_e6:             #e6e6e6;
$gray_a:              #AAAAAA;
$blue:                #037AFF;
$green:               #249336;
$brown:               #8E5C06;
$light-yellow:        #FFC107;
$yellow:              #D48D14;
$red:                 #DA2346;
$dark-blue:           #101928;
$sidebar-blue:        #1A263A;
$discount-blue:       #383F60;
$calendar-gray:       #dee3e7;
$calendar-light-gray: #eef0f2;
$calender-blue:       #d7eaff;

$card-blue:           #3C7ECC;
$card-red:            #D26464;
$card-yellow:         #D9BD49;
$card-green:          #52b963;

$black_15:            rgba(0, 0, 0, 0.15);
$blue-alpha:          rgba(131, 147, 182, 0.5);

// Font Sizes
$giant-size:            48px;
$huge-size:             35px;
$larger-size:           20px;
$large-size:            18px;
$base-size:             16px;
$middle-size:           14px;
$normal-size:           13px;
$small-size:            12px;
$tiny-size:             10px;

// Heading Sizes
$h1:                    28px;
$h2:                    25px;
$h3:                    22px;
$h4:                    $large-size;
$h5:                    $base-size;

// Shadows
$shadow: 0 2px 5px $black_15;
$light-shadow: 0 2px 5px rgba(164, 173, 186, 0.15);
$content-padding: 20px;

// Others
$small-radius: 3px;
$normal-radius: 5px;
$medium-radius: 6px;
$big-radius: 10px;