@import 'styles/helpers/_extends';

.spa__button {
    &.large {
        button {
            padding: 12px;
            @extend %f-bold;
        }
    }

    &.huge {
        button {
            padding: 14px;
            font-size: $large-size;
        }
    }

    &.danger {
        button {
            background-color: $red;
            border-color: $red;
            color: $white;
        }
    }

    &.small {
        button {
            padding: 5px 10px;
            font-size: $tiny-size;
        }
    }

    &.warning {
        button {
            background-color: $yellow;
            color: $white;
        }
    }
    
    button {
        @extend %f-medium;
        border-radius: $normal-radius;
        padding: 10px;
        line-height: 1;
        height: auto;
        width: 100%;

        &.ant-btn-round {
            border-radius: 20px;
        }

        &[disabled] {
            background-color: $gray_e;
            pointer-events: none;
            border-color: $gray_e;
        }
    }
}