$path: '../../assets/fonts/icomoon';

@font-face {
    font-family: 'icomoon';
    src: url($path + '/icomoon.eot?izzx91');
    src: url($path + '/icomoon.eot?izzx91#iefix') format('embedded-opentype'),
        url($path + '/icomoon.ttf?izzx91') format('truetype'),
        url($path + '/icomoon.woff?izzx91') format('woff'),
        url($path + '/icomoon.svg?izzx91#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-invoice:before {
    content: "\e93b";
}

.icon-filter:before {
    content: "\e939";
}

.icon-plus-circle:before {
    content: "\e93a";
}

.icon-printer:before {
    content: "\e938";
}

.icon-alarm:before {
    content: "\e900";
}

.icon-arrow-left:before {
    content: "\e901";
}

.icon-bad:before {
    content: "\e902";
}

.icon-bell:before {
    content: "\e903";
}

.icon-branch:before {
    content: "\e904";
}

.icon-cake:before {
    content: "\e905";
}

.icon-calendar:before {
    content: "\e906";
}

.icon-calendar-2:before {
    content: "\e907";
}

.icon-camera:before {
    content: "\e908";
}

.icon-cancel:before {
    content: "\e909";
}

.icon-chevron-down:before {
    content: "\e90a";
}

.icon-chevron-left:before {
    content: "\e90b";
}

.icon-chevron-right:before {
    content: "\e90c";
}

.icon-clock:before {
    content: "\e90d";
}

.icon-close:before {
    content: "\e90e";
}

.icon-collapse:before {
    content: "\e90f";
}

.icon-creditcard:before {
    content: "\e910";
}

.icon-cup:before {
    content: "\e911";
}

.icon-dashboard:before {
    content: "\e912";
}

.icon-diamond:before {
    content: "\e913";
}

.icon-done:before {
    content: "\e914";
}

.icon-dots:before {
    content: "\e915";
}

.icon-download:before {
    content: "\e916";
}

.icon-expand:before {
    content: "\e917";
}

.icon-fly:before {
    content: "\e918";
}

.icon-folder:before {
    content: "\e919";
}

.icon-gear:before {
    content: "\e91a";
}

.icon-gender:before {
    content: "\e91b";
}

.icon-gift:before {
    content: "\e91c";
}

.icon-global:before {
    content: "\e91d";
}

.icon-good:before {
    content: "\e91e";
}

.icon-graph:before {
    content: "\e91f";
}

.icon-grid-view:before {
    content: "\e920";
}

.icon-home:before {
    content: "\e921";
}

.icon-like:before {
    content: "\e922";
}

.icon-list-view:before {
    content: "\e923";
}

.icon-location:before {
    content: "\e924";
}

.icon-logout:before {
    content: "\e925";
}

.icon-money:before {
    content: "\e926";
}

.icon-normal:before {
    content: "\e927";
}

.icon-note:before {
    content: "\e928";
}

.icon-phone:before {
    content: "\e929";
}

.icon-pie:before {
    content: "\e92a";
}

.icon-plus:before {
    content: "\e92b";
}

.icon-reload:before {
    content: "\e92c";
}

.icon-remove:before {
    content: "\e92d";
}

.icon-return:before {
    content: "\e92e";
}

.icon-search:before {
    content: "\e92f";
}

.icon-shopping-cart:before {
    content: "\e930";
}

.icon-star:before {
    content: "\e931";
}

.icon-suitecase:before {
    content: "\e932";
}

.icon-trash:before {
    content: "\e933";
}

.icon-user:before {
    content: "\e934";
}

.icon-wallet:before {
    content: "\e935";
}

.icon-wallet-2:before {
    content: "\e936";
}

.icon-wifi:before {
    content: "\e937";
}