@import 'styles/helpers/_variables';
@import 'styles/helpers/_mixins';

@keyframes loading {
    0% { transform: rotate(0deg); }
    50% { transform: rotate(180deg); opacity: 0.5 }
    100% { transform: rotate(360deg); }
}

%f-bold {
    font-family: 'Roboto' !important;
    font-weight: bold !important;
    font-style: normal !important;
}

%f-medium {
    font-family: 'Roboto' !important;
    font-weight: 500 !important;
    font-style: normal !important;
}

%f-regular {
    font-family: 'Roboto' !important;
    font-weight: normal !important;
    font-style: normal !important;
}

%hoverable {
    transition: opacity 0.3s ease-in-out;
    &:hover {
        @include opacity(0.9);
    }
}

%ellipse-text {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

%sticky-issue {
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
}

%enable-transition {
    transition: all 0.3s ease-in-out;
}

%loading-icon {
    &:after {
        content: '';
        display: inline-block;
        width: 50px;
        height: 50px;
        border: 4px solid $gray_d;
        border-left-color: $dark-blue;
        border-radius: 25px;
        animation: loading 1s linear infinite;
    }
}