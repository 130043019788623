@import 'styles/helpers/_extends';

.spa__date-picker,
.spa__date-range-picker {
    .ant-picker {
        padding: 5px 10px 6px 10px;
        position: relative;
        border-radius: $normal-radius;
        width: 100%;

        input {
            cursor: pointer;
        }

        .ant-picker-range-separator {
            .ant-picker-separator {
                display: none;
            }
            &:after {
                content: '-';
            }
        }

        &-body {
            .ant-picker-content {
                td {
                    &:hover {
                        + td {
                            background-color: $gray_d;
                        }
                    }
                }
            }
        }
    }

    &.capitalize {
        input {
            text-transform: capitalize;
        }
    }

    .ant-form-item-label {
        label {
            @extend %f-medium;
        }
    }

    .ant-form-item-explain {
        font-size: $small-size;
        > div {
            margin-top: 5px;
        }
    }

    &.ant-form-item-has-error {
        margin: 0;
    }

    &.button-mode {
        .spa__button {
            i {
                font-size: $small-size;
            }

            .ant-btn {
                &.prev {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    margin-right: -1px;
                }

                &.next {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    margin-left: -1px;
                }

                &.today {
                    @extend %f-regular;
                    border-radius: 0;
                }

                &:focus, &:hover {
                    position: relative;
                    z-index: 2;
                }
            }
        }
    }

    &.large {
        .ant-picker {
            padding: 11px 14px;
        }
    }

    .ant-picker-suffix {
        font-size: $small-size;
        margin-right: -5px;
    }
}

.spa__date-picker {
    margin: 0;
    margin-left: -1px;
    .ant-picker {
        &.ant-picker-focused {
            z-index: 2;
        }

        &:hover {
            z-index: 2;
        }
    }
}