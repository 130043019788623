@import 'styles/helpers/_extends';

$dot-size: 18px;
$avatar-size: 45px;
$icon-size: 40px;

.spa__header {
    height: 70px;
    background-color: $white;
    padding: 0 15px;
    box-shadow: $light-shadow;
    .h1 {
        flex-grow: 1;
    }

    &--menu {
        li {
            .spa__button {
                margin: 0 5px;
                button {
                    padding-right: 20px;
                }

                .spa__header--btn {
                    &--dot {
                        width: $dot-size;
                        height: $dot-size;
                        border-radius: $dot-size;
                        background-color: $red;
                    }
                }
            }

            .ant-avatar {
                cursor: pointer;
            }

            .spa__header--icon {
                margin-right: 10px;
                background-color: $white;
                border-radius: $icon-size;
                @extend %enable-transition;
                width: $icon-size;
                height: $icon-size;
                @include flex(row, center, center);
                cursor: pointer;

                i {
                    color: gray_7;
                    @include opacity(0.8);
                }

                &:hover {
                    background-color: $gray_e6;
                }
            }
        }
    }
}

.spa__my-profile-block {
    .ant-image {
        border: 2px solid rgba($blue, 0.2);
        border-radius: $avatar-size;
        overflow: hidden;
        height: 60px;
    }

    &--info {
        .spa__link {
            font-size: $normal-size;
        }
    }
}