@import 'styles/helpers/_extends';

.spa__select {
    .ant-form-item {
        .ant-form-item-label {
            label {
                @extend %f-medium;
            }
        }

        .ant-form-item-explain {
            font-size: $small-size;
            > div {
                margin-top: 5px;
            }
        }

        &.ant-form-item-has-error {
            margin: 0;
        }
    }
    
    .ant-select {
        .ant-select-selector {
            height: auto;
            padding: 3px 30px 3px 10px;
            border-radius: 5px;
            transition: none;

            .ant-select-selection-search {
                width: auto !important;
                margin: 0;
                .ant-select-selection-search-input {
                    height: 100%;
                    min-width: 10px;
                }
            }
        }
    }

    &.placeholder {
        .ant-select {
            &-selector {
                padding: 0 !important;
                border: 0;
                border-radius: 0;
                transition: none;
            }
            .ant-select-selector {
                transition: none;
                .ant-select-selection-search {
                    position: relative;
                    padding: 8px 30px 8px 15px;
                    border: 1px solid $gray_d;
                    border-radius: $normal-radius;
                    line-height: 30px;
                    transition: none;

                    &:before {
                        content: 'Chọn kỹ thuật viên';
                        color: #bbb;
                        @include centered-position(false, true);
                    }
                }
            }
        }

        .ant-select-focused .ant-select-selector,
        .ant-select-selector:focus,
        .ant-select-selector:active,
        .ant-select-open .ant-select-selector {
            border-color: #d9d9d9 !important;
            box-shadow: none !important;
        }
    }

    &.search {
        .ant-select {
            .ant-select-selector {
                .ant-select-selection-search {
                    &:before {
                        content: '';
                    }
                }
            }
        }
    }

    &.large {
        .ant-select {
            .ant-select-selector {
                padding: 8px 30px 8px 15px;
            }
        }
    }
}