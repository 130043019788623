@import 'styles/helpers/_extends';

$input-height: 48px;

.spa__input {
    .ant-form-item {
        &-label {
            label {
                @extend %f-medium;
            }
        }

        &-control {
            &-input {
                &-content {
                    .ant-input-password {
                        padding: 0 10px 0 0;
                        border-radius: $normal-radius;
                    }

                    input,
                    textarea {
                        padding: 14px;
                        border-radius: $normal-radius;
                        background-color: $white;
                        border-color: 1px solid $gray_d;
                        line-height: 1;

                        &[disabled] {
                            background-color: $gray_e;
                            pointer-events: none;
                        }
                    }

                    textarea {
                        min-height: 150px;
                    }

                    .ant-input-affix-wrapper {
                        padding: 0 14px;
                        border-radius: $normal-radius;

                        &.ant-input-password {
                            padding-left: 0;
                        }
                    }
                }
            }
        }

        &-explain {
            font-size: $small-size;
            > div {
                margin-top: 5px;
            }
        }
    }

    &.small {
        .ant-form-item {
            &-control {
                &-input {
                    &-content {
                        input,
                        textarea {
                            padding: 8px;
                            padding-bottom: 9px;
                        }
                    }
                }
            }
        }
    }

    &.tiny {
        .ant-form-item {
            &-control {
                &-input {
                    &-content {
                        input,
                        textarea {
                            padding: 4px 8px;
                        }
                    }
                }
            }
        }
    }

    .ant-input-group-wrapper {
        &.ant-input-search {
            .ant-input-affix-wrapper {
                padding-left: 30px;
                border-radius: $normal-radius;
                overflow: hidden;
            }

            .ant-input-group-addon {
                @include centered-position(false, true);
                background-color: transparent;
                left: 5px;
                z-index: 2;
                button {
                    border: none;
                    background-color: transparent;
                }
            }
        }
    }
}