$path:                          '../../assets/fonts/roboto/';
$RobotoBold:                    $path + 'Roboto-Bold';
$RobotoMedium:                  $path + 'Roboto-Medium';
$RobotoRegular:                 $path + 'Roboto-Regular';

@font-face {
    font-family: 'Roboto';
    src: url($RobotoBold + '.eot');
    src: url($RobotoBold + '.eot?#iefix') format('embedded-opentype'),
        url($RobotoBold + '.woff2') format('woff2'),
        url($RobotoBold + '.woff') format('woff'),
        url($RobotoBold + '.ttf') format('truetype'),
        url($RobotoBold + '.svg#Roboto-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url($RobotoMedium + '.eot');
    src: url($RobotoMedium + '.eot?#iefix') format('embedded-opentype'),
        url($RobotoMedium + '.woff2') format('woff2'),
        url($RobotoMedium + '.woff') format('woff'),
        url($RobotoMedium + '.ttf') format('truetype'),
        url($RobotoMedium + '.svg#Roboto-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url($RobotoRegular + '.eot');
    src: url($RobotoRegular + '.eot?#iefix') format('embedded-opentype'),
        url($RobotoRegular + '.woff2') format('woff2'),
        url($RobotoRegular + '.woff') format('woff'),
        url($RobotoRegular + '.ttf') format('truetype'),
        url($RobotoRegular + '.svg#Roboto-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

// Fonts classes
.f-bold {
    font-family: 'Roboto' !important;
    font-weight: bold !important;
    font-style: normal !important;
}

.f-medium {
    font-family: 'Roboto' !important;
    font-weight: 500 !important;
    font-style: normal !important;
}

.f-regular {
    font-family: 'Roboto' !important;
    font-weight: normal !important;
    font-style: normal !important;
}