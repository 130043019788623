@import 'styles/helpers/_extends';

.spa__pictures-uploader {
    .ant-upload-list {
        @include flex(row);
        flex-wrap: wrap;
        &-picture-card-container {
            order: 1;
            .ant-upload-list-item {
                border-radius: $big-radius;
                position: relative;
                padding: 0;

                &-info {
                    border-radius: $big-radius;
                }

                &-actions {
                    width: 100%;
                    height: 100%;
                    @include flex(row, center, center);
                    button {
                        width: 15px;
                        height: 15px;
                        color: $white;
                        background-color: $red;
                        border-radius: 15px;
                        @include flex(row, center, center);
                        position: absolute;
                        top: -7.5px;
                        right: -7.5px;
                        left: auto;
                        transform: none;

                        span {
                            display: none;
                        }

                        &:after {
                            content: '\e92d';
                            font-family: 'icomoon' !important;
                            font-size: 8px;
                        }
                    }
                }
            }
        }
        .ant-upload-select {
            border-radius: $big-radius;
            border: 1px solid $blue;
            @extend %hoverable;
            order: 0;

            i {
                font-size: $h1;
                color: $blue;
            }
        }
    }
}