@import 'styles/helpers/_extends';

.thumb-vertical {
    background-color: rgba($white, 0.2);
    z-index: 100;

    &.dark {
        background-color: rgba($black, 0.2);
    }
}

.thumb-horizontal {
    display: none;
}

.spa__scrollbars {
    flex-grow: 1;
    > div {
        overflow-x: hidden !important;
        margin-bottom: 0 !important;
    }
}