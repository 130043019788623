@import 'styles/helpers/_extends';

$client-avatar-size: 45px;
$time-width: 46px;
$cell-width: 225px;
$cell-height: 30px;
$label-height: 100px;

.spa__calendar {
    flex-grow: 1;
    @include flex(column);

    &--label {
        padding-left: $time-width;
    }
    &--times {
        width: $time-width;
        @include flex(column, center, center);
        position: relative;
    }

    &--time {
        font-size: $middle-size;
        height: $cell-height;
    }

    &--days {
        flex-grow: 1;
        position: relative;
        width: 102%;
        overflow: hidden;
    }
    
    &--day {
        width: calc(100% / 7);
        // min-width: 188px;
        position: relative;
        &--label {
            @include flex(column, center, center);
            height: $label-height;
            .avatar {
                font-size: 30px;
                width: $client-avatar-size;
                height: $client-avatar-size;
                border-radius: $client-avatar-size;
                background-color: $white;
                @include flex(row, center, center);
                @extend %f-regular;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            p {
                font-size: $middle-size;
            }

            &.date {
                .avatar {
                    background-color: $blue;
                    h1 {
                        color: $white;
                    }
                }
            }

            &.blank {
                .h1 {
                    box-shadow: $shadow;
                }
            }
        }

        &--content {
            background-size: 8px 8px;
            background-image: linear-gradient(45deg, transparent 46%, rgba(16, 25, 40, 0.2) 49%, rgba(16, 25, 40, 0.2) 51%, transparent 55%);
            background-color: $calendar-light-gray;
        }

        .spa__calendar--cell {
            &:nth-child(4n + 1) {
                border-top-color: $calendar-gray;
            }
        }
    }

    .current-time,
    .current-drag-time {
        @include centered-position(true, false);
        z-index: 21;
        padding: 2px 4px;
        border: 2px solid $red;
        border-radius: 15px;
        color: $red;
        font-size: $small-size;
        background-color: $white;
        pointer-events: none;
    }

    .current-drag-time {
        border-color: $blue;
        color: $blue;
    }

    .current-time-line {
        position: absolute;
        width: calc(100% + 5px);
        height: 2px;
        background-color: $red;
        margin-top: 10px;
        left: -5px;
        z-index: 20;
        pointer-events: none;
    }

    &.three-day {
        .spa__calendar--day {
            width: calc(100% / 3);
        }
    }

    &--cell {
        border: 1px solid $calendar-light-gray;
        height: $cell-height;
        border-bottom-color: transparent;
        border-right-color: transparent;
        border-left-color: $calendar-gray;
        @include flex(row, center);
        color: transparent;
        padding-left: 5px;
        font-size: $middle-size;
        @extend %enable-transition;
        user-select: none;

        &.working {
            background-color: $white;
        }

        &:hover {
            background-color: $calender-blue;
            border-color: $calender-blue;
            position: relative;
            z-index: 0;
            color: $blue;
            cursor: pointer;
        }
    }

    &--item {
        border-radius: $normal-radius;
        position: absolute;
        box-shadow: $shadow;
        z-index: 10;
        cursor: pointer;
        @extend %hoverable;
        width: 96%;
        overflow: hidden;
        @include flex(column);
        
        &--content {
            position: relative;
            padding: 5px;
            flex-grow: 1;
            .h5 {
                color: $text-gray;
                font-size: $middle-size;
                span {
                    &:first-child {
                        font-size: $small-size;    
                        width: 65px;
                    }

                    &:last-child {
                        width: calc(100% - 90px);
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }

            p {
                color: $text-gray;
                font-size: $small-size;
            }

            .spa__status-icon {
                position: absolute;
                top: 5px;
                right: 5px;
                margin: 0;
            }

            .icon-invoice {
                position: absolute;
                bottom: 10px;
                right: 10px;
                color: $text-gray;
                pointer-events: none;
            }
        }

        .ui-resizable-s {
            bottom: 0;
            cursor: s-resize;
            left: 0;
            @include flex(row, center, center);
            padding: 3px 5px;
            z-index: 10;
            position: absolute;
            width: 100%;
            @include opacity(0);
            visibility: hidden;
            @extend %enable-transition;

            &:after {
                content: '=';
                font-size: $h3;
            }
        }

        &:hover {
            .ui-resizable-s {
                @include opacity(1);
                visibility: visible;
            }
        }

        &--staff {
            .avatar {
                width: 20px;
                height: 20px;
                border-radius: 20px;
                background-color: $gray_e;
                @include flex(row, center, center);
                font-size: $middle-size;
                overflow: hidden;

                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
    }

    &--item--popover {
        .ant-popover-content {
            .ant-popover-inner {
                border-radius: $big-radius;
                min-width: 260px;
                overflow: hidden;
            }
        }
        
        &.no-padding {
            .ant-popover-content {
                .ant-popover-inner {
                    &-content {
                        padding: 0;
                    }
                }
            }
        }
    }

    &--grid {
        position: relative;
        flex-grow: 1;
        @include flex(column);
    }

    &--btn {
        position: absolute;
        top: 20px;
        z-index: 100;
        @include opacity(0.5);
        @extend %enable-transition;

        &:hover {
            @include opacity(1);
        }

        &.prev {
            left: 66px;
        }

        &.next {
            right: 20px;
        }
    }
}

.spa__appointment--popover {
    .note {
        font-style: italic;
        font-size: $middle-size;
    }

    .spa__appointment--client--info {
        padding-bottom: 0;
    }

    .spa__appointment--time {
        border: none;

        .info {
            .info--note {
                font-size: $middle-size;
                color: $gray_9;
            }
        }

    }
}