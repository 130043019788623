.h1 {
    font-size: $h1;
}

.h2 {
    font-size: $h2;
}

.h3 {
    font-size: $h3;
}

.h4 {
    font-size: $h4;
}

.h5 {
    font-size: $h5;
}

.spa__link {
    font-size: $base-size;
    text-decoration: none;
    color: $blue;
    @extend %hoverable;

    i {
        font-size: $small-size;
        margin-right: 5px;
    }
}

.spa__error {
    color: $red;
}

.spa__box {
    background-color: $white;
    border-radius: $big-radius;
    box-shadow: $shadow;
    @include flex(column);
}

input, textarea, select {
    @include placeholder() {
        color: $gray_d;
    }
}

.spa__auth {
    @include flex(column);
    min-height: 100vh;

    .note {
        color: $gray_8;
    }

    .spa__app-download {
        flex-grow: 1;
    }
}

.ant-dropdown {
    &.add-new {
        .ant-dropdown-menu {
            min-width: 150px;
            padding: 0;
            text-align: center;
            &-item {
                padding: 10px;
                border-top: 1px solid $gray_d;
                position: relative;
    
                &:first-child {
                    border: none;
                }

                .excel-upload {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    @include opacity(0);

                    .icon-camera {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }

    &.header {
        box-shadow: $shadow;
        border-radius: $big-radius;
        overflow: hidden;
        margin-top: 15px;

        .ant-dropdown-menu {
            padding: 0;

            &-item {
                padding: 15px;
                border-top: 1px solid $gray_d;

                &:first-child {
                    border: none;
                }

                &.no-hover {
                    pointer-events: none;
            
                    a {
                        pointer-events: all;
                    }
                    
                    &:hover {
                        background-color: $white;
                    }
                }
            }
        }
    }
}

.spa__status {
    font-size: $small-size;
    &.new {
        color: $blue;
    }

    &.confirmed {
        color: $blue;
    }

    &.in-progress,
    &.paying,
    &.doing,
    &.draft {
        color: $yellow;
    }

    &.finished,
    &.finish {
        color: $green;
    }

    &.cancelled,
    &.canceled {
        color: $red;
    }

    &.v2 {
        color: $white;
        border-radius: 20px;
        padding: 3px 5px;
        text-align: center;
        text-transform: uppercase;
        font-size: $tiny-size;
        &.new {
            background-color: $blue;
        }
    
        &.confirmed {
            background-color: $blue;
        }
    
        &.in-progress,
        &.paying,
        &.doing,
        &.draft {
            background-color: $yellow;
        }
    
        &.finish,
        &.finished {
            background-color: $green;
        }
    
        &.cancelled,
        &.canceled {
            background-color: $red;
        }
    }
}

.spa__badge {
    font-size: $small-size;
    display: inline-block;
    padding: 3px 8px;
    background-color: $gray_d;
    margin: 0;
    border-radius: 5px;

    &.warning {
        color: $white;
        background-color: $yellow;    
    }

    &.new {
        background-color: $blue;
        color: $white;
    }
}

$table-cell-size: 38px;

.ant-picker-dropdown {
    .ant-picker-panel-container {
        border-radius: 10px;
        box-shadow: $shadow;
    }
    .ant-picker-date-panel {
        width: auto;
        .ant-picker-header {
            padding: 10px 20px 0 20px;
            border: none;
            .ant-picker-header-super-prev-btn,
            .ant-picker-header-super-next-btn {
                display: none;
            }

            .ant-picker-header-view {
                button {
                    font-size: $large-size;
                }
            }
        }

        .ant-picker-body {
            padding: 10px 20px;

            .ant-picker-content {
                thead {
                    tr {
                        th {
                            font-size: $large-size;
                            @extend %f-medium;
                            padding: 10px;
                            width: $table-cell-size * 1.4;
                        }
                    }
                }
                tbody {
                    tr {
                        td {
                            &:before {
                                display: none;
                            }

                            &:after {
                                display: none;
                            }
                            .ant-picker-cell-inner {
                                border-radius: $table-cell-size;
                                width: $table-cell-size;
                                height: $table-cell-size;
                                margin: 0 auto;
                                @include flex(row, center, center);
                                border: none;

                                &:before {
                                    display: none;
                                }

                                &:after {
                                    display: none;
                                }
                            }

                            &.ant-picker-cell-range-hover,
                            &:hover,
                            &.ant-picker-cell-in-range {
                                .ant-picker-cell-inner {
                                    background-color: $gray_d !important;
                                }
                            }

                            &.ant-picker-cell-selected,
                            &.ant-picker-cell-range-start,
                            &.ant-picker-cell-range-end {
                                .ant-picker-cell-inner {
                                    background-color: $text-gray !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.appointment {
        .ant-picker-panel-container {
            position: relative;
            left: -137px;
        }
    }

    &.week {
        .ant-picker-content {
            tr {
                
            }
        }
    }
}

.ant-select-dropdown {
    padding: 0;
    .rc-virtual-list-holder-inner {
        .ant-select-item {
            &.ant-select-item-option-selected {
                background-color: $blue;
                @extend %f-regular;
                color: $white;
            }
        }
    }

    &.add-new {
        .rc-virtual-list-holder-inner {
            .ant-select-item {
                padding: 10px;
                border-top: 1px solid $gray_d;
                &.ant-select-item-option-selected {
                    background-color: $white;
                    color: $text-gray;
                }

                &:first-child {
                    border: none;
                }

                &-option-content {
                    @include flex(row, center);
                    span {
                        flex-grow: 1;
                        text-align: center;
                        @extend %f-regular;
                    }
                }
            }
        }
    }
}

.spa__border-box {
    padding: 15px;
    border-bottom: 1px solid $gray_d;
}

.ant-popover {
    &.frequency {
        .ant-popover-content {
            .ant-popover-inner {
                width: 325px;
                border-radius: 10px;
                box-shadow: $shadow;
            }
        }
    }
}

$client-avatar-size: 45px;
.spa__appointment--client {
    flex-grow: 1;
    @include flex(column);
    &--info {
        .avatar {
            width: $client-avatar-size;
            height: $client-avatar-size;
            border-radius: $client-avatar-size;
            background-color: $gray_e;
            @include flex(row, center, center);
            overflow: hidden;
            h1 {
                font-size: 30px;
            }

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        .client-info {
            flex-grow: 1;
            &--name {
                margin: 0;
            }

            &--phone {
                font-size: $middle-size;
                color: $gray_9;
            }
        }

        .icon-dots {
            cursor: pointer;
        }

        ul {
            padding: 20px;
            li {
                align-items: center;
                justify-content: space-between;
                padding: 5px 0;
                p {
                    &:first-child {
                        color: $gray_9;
                    }
                }
            }
        }
    }
}

$status-icon-size: 20px;
.spa__status-icon {
    width: $status-icon-size;
    height: $status-icon-size;
    background-color: $blue;
    border-radius: $status-icon-size;
    font-size: $tiny-size;
    color: $white;
    margin-right: 5px;
    @include flex(row, center, center);
    &.new {
        background-color: $blue;
    }

    &.confirmed {
        background-color: $blue;
    }

    &.in-progress,
    &.unpaid,
    &.doing {
        background-color: $yellow;
    }

    &.done,
    &.finished {
        background-color: $green;
    }

    &.cancelled,
    &.canceled {
        background-color: $red;
    }
}

.ant-tabs {
    @include flex(column);
    flex-grow: 1;
    &-nav {
        &-list {
            .ant-tabs-tab {
                @extend %f-medium;
                color: $gray_9;

                &.ant-tabs-tab-active {
                    color: $text-gray;
                }
            }
        }
    }

    &-content {
        flex-grow: 1;
    }

    &-content-holder {
        @include flex(column);
    }
}

$sales-input-width: 365px;
.spa__sales {
    height: 100%;
    .ant-tabs {
        box-shadow: $light-shadow;
        height: 100%;
        &-nav {
            background-color: $white;
            margin: 0;
            padding: 0 15px;
 
            &-list {
                .ant-tabs-tab {
                    padding: 0 0 5px 0;
                }
            }
        }
    }

    &--filter {
        padding: $content-padding;

        .spa__input {
            min-width: $sales-input-width;
            max-width: 100%;
        }
    }
}

.table-analytic {
    position: relative;
    tr {
        th {
            background-color: $white;
        }

        td, th {
            &:first-child {
                padding-left: 20px;
            }

            &:last-child {
                padding-right: 20px;
            }
        }
    }

    .ant-table-footer {
        background-color: $white;
        text-align: center;
        color: $gray_9;
        position: absolute;
        left: 0;

        p {
            margin: 0;
        }
    }

    .ant-pagination {
        padding: 0 $content-padding;
    }
}

.spa__appointment--content {
    padding: 15px;
    .done {
        .spa__appointment--review--icons {
            .review--icon {
                @extend %hoverable;
                border: none;
                background-color: transparent;
                width: 30%;

                i {
                    font-size: $h2;

                    &.icon-good {
                        color: $green;
                    }

                    &.icon-normal {
                        color: $yellow;
                    }

                    &.icon-bad {
                        color: $red;
                    }
                }

                p {
                    @extend %f-regular;
                    margin-top: 10px;
                    white-space: initial;
                }

                .ant-btn {
                    border-color: transparent;
                    border-width: 2px;

                    &.ant-btn-primary {
                        background-color: $white;
                        border-color: $dark-blue;
    
                        p {
                            color: $text-gray;
                        }
                    }
                }
            }
        }
    }

    .completed {
        .h1 {
            i {
                font-size: $h1 * 3;
                color: $green;
            }
        }
    }

    .unpaid {
        .h1 {
            i {
                font-size: $h1 * 3;
                color: $red;
            }
        }
    }

    .new {
        .h1 {
            i {
                font-size: $h1 * 1.5;
            }
        }
    }
}

$progress-height: 18px;
.spa__progress {
    height: $progress-height;
    border-radius: $progress-height;
    width: 40%;
    background-color: $gray_c;
    font-size: $tiny-size;
    color: $white;
    position: relative;

    &.full {
        width: 100%;
    }

    &--label {
        line-height: $progress-height;
        padding-left: 10px;
        position: relative;
        z-index: 1;
    }

    &--done {
        display: inline-block;
        background-color: $green;
        height: $progress-height - 4px;
        border-radius: $progress-height;
        @include centered-position(false, true);
        left: 2px;
    }
}

.spa__add-appointment--btns {
    padding: 15px;
    border-top: 1px solid $gray_d;
    
    &--group {
        .spa__button {
            width: calc(50% - 10px);
        }
    }
}

.ant-drawer {
    .ant-drawer-content-wrapper {
        .ant-drawer-header {
            border-bottom: 0;
            padding-bottom: 10px;

            .ant-drawer-title {
                font-size: $h2;
                @extend %f-bold;
            }

            .ant-drawer-close {
                i {
                    font-size: $normal-size;
                }
            }
        }
    }
}

.spa__rank {
    @include flex(true, center);
    i {
        margin-right: 5px;
    }

    &.blue {
        color: $blue;
    }

    &.brown {
        color: $brown;
    }

    &.grey {
        color: $gray_9;
    }

    &.gold {
        color: $yellow;
    }

    &.diamond {
        color: $green;
    }
}

.date-picker {
    .spa__button {
        i {
            font-size: $small-size;
        }

        .ant-btn {
            &:focus, &:hover {
                position: relative;
                z-index: 2;
            }
        }

        &.prev {
            margin-right: -1px;
            button {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &.next {
            margin-left: -1px;
            button {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        &.today {
            button {
                @extend %f-regular;
                border-radius: 0;
            }
        }
    }

    .spa__date-picker,
    .spa__date-range-picker {
        .ant-picker {
            border-radius: 0;

            input {
                text-align: center;
                text-transform: capitalize;
            }
        }
    }
}

.slick-slider {
    .slick-arrow {
        z-index: 5;
        &.slick-prev {
            left: 10px;
        }

        &.slick-next {
            right: 10px;
        }

        &:before {
            color: $text-gray;
        }
    }

    .slick-slide {
        background-color: $gray_f;
    }

    .spa__photo {
        &--header {
            width: calc(100% - 80px);
            margin: 15px auto 0;

            > p {
                span {
                    margin: 0 5px;
                    &.label {
                        color: $gray_9;
                        margin: 0;
                    }
                }
            }
        }
        img {
            display: block;
            margin: 15px auto 30px;
            width: calc(100% - 80px);
        }
    }
}

.spa__invoice-print {
    width: 410px;
    border: 1px solid $text-gray;
    padding: 10px;
    background-color: $white;
    margin: 30px auto;
    line-height: 1.5;
    
    p {
        margin: 0;
        font-size: $middle-size;
    }

    &--header {
        > img, > span {
            width: 150px;
            height: auto;
        }

        .content {
            padding-left: 15px;
        }
    }

    &--title {
        margin: 30px 0;
    }

    &--info {
        p {
            @include flex(row, center);
            line-height: 1.8;

            span {
                &:first-child {
                    min-width: 100px;
                }
            }
        }
    }

    &--items {
        margin: 20px 0;
        table {
            width: 100%;
            th {
                font-size: $middle-size;
                padding: 12px 5px;
            }

            td {
                padding: 5px;
            }

            tr {
                td, th {
                    text-align: right;
                    &:first-child {
                        text-align: left;
                    }
                }

                td, th {
                    border-top: 1px solid $text-gray;
                }
            }

            tbody {
                tr {
                    &:last-child {
                        td {
                            border-bottom: 1px solid $text-gray;
                        }
                    }
                }
            }
        }
    }

    &--payment {
        p, h4 {
            @include flex(row, center, space-between);
            line-height: 2;
        }
    }

    &--note {
        p {
            &:before, &:after {
                content: '****';
            }
        }
    }
}

.print-classes {
    height: 90%;
    overflow: hidden;
    .spa__invoice-print {
        display: block !important;
    }
}

.spa__notify {
    position: fixed;
    top: 10px;
    left: 35%;
    transform: translate(-35%, 0);
    z-index: 1000;
}

.spa__toast {
    @include flex(row, center);
    > img {
        width: 50px;
        border-radius: 50px;
        overflow: hidden;
    }
}

$avatar-size: 176px;
$avatar-icon-size: 56px;
.spa__avatar-uploader.avatar {
    @include flex(row, center, center);
    .ant-upload-select {
        width: $avatar-size;
        height: $avatar-size;
        border-radius: $avatar-size;
        border: none;
        background: $white url(../../assets/images/avatar.png) no-repeat center center;
        background-size: cover;
        margin-bottom: $avatar-icon-size/2;
        position: relative;
        overflow: hidden;

        .ant-icon {
            @include centered-position(true, true);
            cursor: pointer;
            .icon-camera {
                width: $avatar-icon-size;
                height: $avatar-icon-size;
                background-color: $white;
                box-shadow: $shadow;
                border-radius: $avatar-icon-size;
                display: inline-block;
                @include flex(row, center, center);
                font-size: $h1;
                color: $blue;
                position: absolute;
                bottom: -85px;
                left: 50%;
                transform: translate(-50%, 0);
            }

            &.loading {
                background-color: rgba($white, 0.5);
                width: 100%;
                height: 100%;
                @include flex(row, center, center);
            }
        }

        .avatar {
            border-radius: $avatar-size;
        }
    }
}

$status-width: 236px;
.spa__appointment--status {
    .spa__select {
        .ant-select {
            .ant-select-selector {
                width: $status-width;
                box-shadow: $shadow;
                border-radius: 30px;
                padding-left: 0;
                padding-right: 0;
            }
    
            .ant-select-selection-item {
                @include flex(row, center, center);
    
                .spa__status-icon {
                    margin-right: 5px;
                }
            }
        }
    }
}

.spa__news {
    @include flex(column, center);
    img {
        width: 150px;
        max-width: 95%;
    }

    h3 {
        border-top: 1px solid $gray_d;
        width: 100%;
    }
}